// @ts-ignore
globalThis.__VUE_OPTIONS_API__ = true;
// @ts-ignore
globalThis.__VUE_PROD_DEVTOOLS__ = false;
// @ts-ignore
globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

const { remoteFragments } = require("./utils/moduleConfig.js");

// Define remote fragments on window
for (const key of Object.keys(remoteFragments)) {
  const value = remoteFragments[key];

  //@ts-ignore
  window[
    value.moduleDOMVariable
  ] = `https://${process.env.BACKOFFICE_DNS}${value.subDomain}`;
}

// Manually configure local remote fragments (do not put into production!)
// window.marketplaceFragmentUrl = "https://local.autoproff.com:1010"
// window.managedFragmentUrl = "https://local.autoproff.com:1008"
// window.buyNowFrontendUrl = "https://local.autoproff.com:8083"
// window.listingSearchUrl = "https://local.autoproff.com:8092"

import("./utils/shell.ts");
import("./main.js");

export {};
