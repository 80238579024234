import Cookies from "js-cookie";
import * as auth from "@autoproff/auth-web";
import { decode, type Token } from "@autoproff/token";
import { getEnvInfo } from "@autoproff/environment";

const getCookieDomain = () => {
  // Take the two last parts of the hostname, e.g. "autoproff.com"
  return `.${window.location.hostname.split(".").slice(-2).join(".")}`;
};

export { login, logout, exchangeToken, refreshToken, removeAuthenticationCookies, setAccessCookies } from "@autoproff/auth-web";

/**
 * Get the auth cookie name
 * TODO: remove with USE_JUMP_COOKIE
 * @returns auth cookie name
 */
export const getAuthCookieName = () => auth.getAuthCookieName();

/**
 * Get the access token
 * @returns access token
 */
export const getAccessToken = () => Cookies.get(getAuthCookieName());

/**
 * Get the decoded access token
 * @returns decoded access token
 */
export const getDecodedAccessToken = (): null | Token => auth.readAccessToken();

/**
 * Decode the access token
 * NOTE: Returns kept as-is for backward compatibility
 * @param accessToken access token
 * @returns decoded access token
 */
export const decodeAccessToken = (accessToken: string | undefined) => {
  if (!accessToken) return {};

  try {
    const decoded = decode(accessToken);
    if (!decoded) return {};
    return decoded;
  } catch (error) {
    console.error(`Failed to decode token: ${error}`);
    return {};
  }
};

export const IMPERSONATION_COOKIE_NAME = `${getEnvInfo().sharedCookiePrefix}_access_token_jump`;

/**
 * Get the impersonation token aka. jump token from the cookies
 * @returns impersonation token
 */
export const getImpersonationToken = () => Cookies.get(IMPERSONATION_COOKIE_NAME);

/**
 * Remove the impersonation token aka. jump token from the cookies.
 */
export const removeImpersonationToken = () =>
  Cookies.remove(IMPERSONATION_COOKIE_NAME, { domain: getCookieDomain() });

/**
 * Update the impersonation token aka. jump token in the cookies.
 * @param token impersonation token
 */
export const updateImpersonationToken = (token: string) =>
  Cookies.set(IMPERSONATION_COOKIE_NAME, token, { domain: getCookieDomain() });
