// @ts-check
import { decode } from "@autoproff/token";
import * as auth from "../../node_modules/@autoproff/auth-web";
import { getImpersonationToken, updateImpersonationToken, IMPERSONATION_COOKIE_NAME } from "./auth";

export const getAuthCookieName = () => {
    return getImpersonationToken()
        ? IMPERSONATION_COOKIE_NAME
        : auth.getAuthCookieName();
};

export const getAccessToken = () => {
    const jump = getImpersonationToken();
    return jump ? jump : auth.getAccessToken();
}

export const readAccessToken = () => {
    const jump = getImpersonationToken();
    return jump ? decode(jump) : auth.readAccessToken();
}

export const setAccessCookies = (/** @type {{ access_token: string; }} */ accessTokenObject) => {
    const jump = getImpersonationToken();
    jump ? updateImpersonationToken(accessTokenObject.access_token) : auth.setAccessCookies(accessTokenObject);
}

/**
 * DISCLAIMER: Strictly speaking we should override all methods from auth-web,
 * but our assumption is that logins are only impersonated for a short period
 * of time, which would make overriding a method like exhangeToken unnecessary.
 **/
export {
    exchangeToken,
    login,
    logout,
    refreshToken,
    removeAuthenticationCookies,
} from "../../node_modules/@autoproff/auth-web";
